import Content from "./components/Content";
function App() {
  return (
        <>

          <Content />
        </>
  );
}

export default App;
